import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Accommodating Different Types Of People`}</h1>
    <div style={{
      "position": "relative",
      "paddingBottom": "56.25%",
      "height": "0"
    }}><iframe src="https://www.loom.com/embed/57af6ecfe91041829446189fee97b612" frameBorder="0" webkitallowfullscreen mozallowfullscreen allowFullScreen style={{
        "position": "absolute",
        "top": "0",
        "left": "0",
        "width": "100%",
        "height": "100%"
      }}></iframe></div>
    <p>{`Our school is filled with so many different types of people. We have people who are interested in a lot of different things. We have students who like music, painting, drawing, dance, technology, sports, leadership, video games, movies, TV shows, chemistry, history, writing, debating, anime, engineering, and so much more! I just listed off an interest that each of my closest friends has, but I'm sure the list goes on way further. Many of these students have programs on campus that support their needs. If they like film, they'll join iTV. If they like music, they'll join the performing arts program. We do an outstanding job at incorporating different skills and talents on campus.`}</p>
    <p>{`Did you know that Esports is officially recognized as a sport by CIF? I see that as a huge win. We're taking steps to make our school more accommodating of people who like gaming. It requires an immense amount of precision and dedication! Irvine's Esports team also took a massive dub recently too... our team is fantastic!`}</p>
    <p>{`Next year, I want to make ASB a place for different kinds of people to come together to spotlight all the fantastic talents and interests that make our school so amazing. When I was interviewing for my council in May 2020, there were so many fantastic applicants. In the end, I wanted to pick people who were involved in different areas on campus that are typically underrepresented. In December, one of my councilmembers coordinated an Irvine High League of Legends tournament. It was by far the most successful virtual event we ran all year, and it's something we've never really tapped into before! It opened my eyes to how many different types of events we could have. What if we had an Irvine High anime fan meetup? We could have a cool trivia game set-up and go super far with it! Irvine Virtual Academy does something similar too, where after TA on Monday, there are IVA Connect sessions where people can talk about stocks, photography, or anything the session is discussing! At the time, I was particularly interested in Bitcoin and Ethereum, so I joined the investments room and had a great time!`}</p>
    <p>{`One of my goals for ASB next year is to make sure we're attending to different kinds of people with different interests. Everyone has their own lives with their own talents and knowledge. Instead of appealing to one or two types of people— typically those who like football games and leadership— we should create event schedules for everyone! One way we can do this is by having a diverse ASB team. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      